<template>
    <div class="tagsDetailPage">
        <HeadBar :titleName="kindName[kind] +' '+ tagName"></HeadBar>
        <div class="content oepnX">
            <div class="topicPageContent">
                <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                             @onRefresh="onRefresh" :isNoData="isNoData" :error="error" :is-higeht-max="true"
                             :adv-list="advList" :hasAdvPagination="true" className="TopicPage">
                    <VerticleThreeList :videoList="videoList" v-if="format == 0"></VerticleThreeList>
                    <TransverselyTwoList :videoList="videoList" v-else></TransverselyTwoList>
                </PullRefresh>
                <Loading v-else />
            </div>
        </div>
    </div>
</template>

<script>
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import {queryTagsmediaList} from "@/api/video";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh"
import HeadBar from "@/components/HeadBar";
import {queryTagInfo} from "@/api/community";

export default {
    name: "tagsDetailPage",
    components:{
        VerticleThreeList,
        TransverselyTwoList,
        Loading,
        PullRefresh,
        HeadBar
    },
    data(){
        return {
            activeName:0,
            pageNumber:1,
            pageSize:20,
            topicId:"",
            zone:"",
            titleName:"",
            format:0,
            videoList:[],
            isLoading:true,
            loading: true,
            refreshing: false,
            finished: false,
            isNoData: false,
            advList: [],
            error: false,
            tagID: "",
            tagInfo: null,
            kind: 0,
            kindName:['动漫','漫画','cosply','真人','小说','写真','原作'],
            tagName:''
        }
    },
    mounted() {
        let query = this.$route.query;
        if(query){
            this.tagID = query.id;
            this.kind = query.kind;
            this.tagName = query.tagName;
            // this.topicId = query['topicId'];
            // this.zone = query['zone'];
            // this.titleName = query['name'];
            // this.format = query['format'];
            this.getTagsmediaList();

            this.$bus.$emit("dataBuriedEvent",{
                "dataType":2,
                tagsName: this.tagName
            });
        }
    },methods:{
        async getTagsmediaList(type) {
            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                tags: this.tagID,
                kind: this.kind
            }
            let res = await this.$Api(queryTagsmediaList, req);

            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            try {
                if(res && res.code == 200){
                    if(type == "refresh"){
                        this.videoList = res.data.MediaList;
                    }else {
                        this.videoList = this.videoList.concat(res.data.MediaList);
                    }

                    if (this.pageNumber === 1 && this.videoList && this.videoList.length === 0) {
                        this.isNoData = true;
                    }
                    if (!res.data.hasNext || res.data.MediaList.length < req.pageSize) {
                        this.finished = true;
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        },onRefresh() {
            this.pageNumber = 1;
            this.refreshing = true;
            this.finished = false;
            this.isNoData = false;
            this.getTagsmediaList('refresh');
        }
        ,onLoad(){
            this.loading = true;
            if (this.error) {
                this.error = false;
            } else {
                this.pageNumber++
            }
            this.getTagsmediaList();
        },clickTag(index){
            if(index != null){
                this.activeName = index;
            }
            this.pageNumber = 1;
            this.finished = false;
            this.loading = true;
            this.isLoading = true
            this.isNoData = false;
            this.getTagsmediaList('refresh');
        }
    }
}
</script>

<style lang="scss" scoped>
.tagsDetailPage{
    height: 100%;

    .content{
        //width: 100%;
        //height: calc(100% - 44px);
        //padding: 0 12px;

        /deep/ .van-tabs__nav--line{
            width: 188px;
            padding-left: 9px;
        }

        .navTabTitle{
            color: rgb(153,153,153);
        }

        .navTabTitleActive{
            color: rgb(113,69,231);
        }

        .topicPageContent{
            height: calc(100vh - 48px);
            padding: 0 10px;
        }

    }
}
</style>
